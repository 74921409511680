<style scoped>
li a:any-link {
  color: white;
  text-decoration: none
}

.router-link-exact-active {
  font-weight: normal !important;
  border-bottom: none;
}

.nav-link {
    white-space: nowrap;
}

</style>

<template>
  <b-container>
    <!-- <div class="title_logo" v-if="'app' in $store.state.config && $store.state.config.app.logo_image">
      <router-link to="/">
        <div v-html="$store.state.config.app.logo_image" class="title_logo_image"></div>
      </router-link>
    </div> -->
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <!-- <b-navbar-brand>Basisbeveiliging</b-navbar-brand> -->
      <b-navbar-nav>
        <b-nav-item to="/" exact exact-active-class="active">
          <b-icon icon="house"/> {{ $t("app.menu.overview") }}
        </b-nav-item>
        <menu-country-layer />
        <b-nav-item :to="`/tracking-cookies/`" accesskey="p" exact exact-active-class="active" v-if="$store.state.config.show.cookie_plaza">
          🍪 {{ $t("app.menu.tracking-cookies") }}
        </b-nav-item>
        <b-nav-item :to="`/leaderboard/`" accesskey="p" exact exact-active-class="active" v-if="$store.state.config.leaderboard.show">
          💚💩 {{ $t("app.menu.leaderboard") }}
        </b-nav-item>
        <b-nav-item to="/public-submissions/" accesskey="u" exact exact-active-class="active" v-if="$store.state.config.public_submissions.enabled">
          🍕 {{ $t("app.menu.public-submissions") }}
        </b-nav-item>
        <b-nav-item to="/datasets" accesskey="t" exact exact-active-class="active" v-if="$store.state.config.show.datasets">
          <b-icon icon="cloud-download"/> {{ $t("app.menu.datasets") }}
        </b-nav-item>
        <b-nav-item to="/about" accesskey="a" exact exact-active-class="active" v-if="$store.state.config.show.intro">
          <b-icon icon="info-circle"/> {{ $t("app.menu.info") }}
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <SwitchLocale/>
        <time-machine />
      </b-navbar-nav>
    </b-navbar>
  </b-container>
</template>


<script>

import {mapState} from 'vuex'
import MenuCountryLayer from "@/components/menu/MenuCountryLayer";
import TimeMachine from "@/components/TimeMachine";
import SwitchLocale from "@/components/SwitchLocale";

export default {
  components: {TimeMachine, MenuCountryLayer, SwitchLocale},
  computed: mapState(['user']),
}

</script>
