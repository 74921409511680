import axios from "axios";

// https://stackoverflow.com/questions/39254562/csrf-with-django-reactredux-using-axios
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export default axios.create({
  responseType: 'json',
  transitional: {
    silentJSONParsing: false,
  }
});
