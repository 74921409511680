<script>
import i18n from "@/i18n";

export default {
  name: "TranslationsMixin",
  i18n: {
    locale: "en",
    fallbackLocale: "en",
    silentFallbackWarn: true,
  },
  methods: {
    updateTranslations() {
      const config = this.$store.state.config;
      for (let locale in config.translation) {
        i18n.setLocaleMessage(
          locale,
          Object.assign(
            {},
            i18n.getLocaleMessage(locale),
            config.translation[locale],
          ),
        );
      }
    },
  },
  // if config is already loaded initialize translations
  created() {
    this.updateTranslations();
  },
  // if config is loaded in at a later point or changes update translations
  watch: {
    "$store.state.config.translations"() {
      this.updateTranslations();
    },
    "$store.state.config.translation"() {
      this.updateTranslations();
    },
  },
};
</script>
