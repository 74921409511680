<style>
body {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 !important;
  min-height: calc(100vh - 230px);
}

h2, h3 {
  padding-bottom: 0.3em;
}
</style>

<template>
  <div id="app">
    <header class="do-not-print" style="background-color: var(--dark)" v-if="!$route.query.skipheader">
      <announcement v-if="$store.state.config.show.announcement === true"></announcement>

      <main-menu-dual-bar/>

      <sub-menu-organization v-if="this.$route.meta.menu === 'organization'"/>

      <management-menu v-if="$store.state.config.admin === true"/>
    </header>

    <main style="background-color: var(--light);">
      <error-page v-if="$loadError"></error-page>
      <keep-alive v-else>
        <router-view></router-view>
      </keep-alive>
    </main>

    <footer class="footer p-2 pt-4 pb-1 do-not-print" style="background-color: var(--dark); overflow-x: auto" v-if="!$route.query.skipfooter">
      <disclaimer v-if="$store.state.config.show.disclaimer === true"></disclaimer>
      <footer-content></footer-content>
    </footer>
  </div>
</template>

<script>

import MainMenuDualBar from "@/components/menu/MainMenuDualBar";
import ErrorPage from "@/pages/ErrorPage";
import TranslationsMixin from "@/components/TranslationsMixin";

export default {
  name: 'App',
  mixins: [TranslationsMixin],
  components: {
    MainMenuDualBar,
    SubMenuOrganization: () => import('@/components/menu/SubMenuOrganization'),
    FooterContent: () => import('@/components/FooterContent'),
    Announcement: () => import('@/components/AnnouncementMessage'),
    Disclaimer: () => import('@/components/DisclaimerMessage'),
    ErrorPage,
    // this menu is optional if admin is enabled, only load when needed
    ManagementMenu: () => import("@/components/menu/ManagementMenu"),
  },
}
</script>
