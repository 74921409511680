import http from "@/httpclient";

const state = () => ({
  // currently set/select date (either by route or timemachine), defaults to today
  currentDate: new Date().toISOString().slice(0, 10),
  // cache of all map data organized by date, country and layer
  perDate: {},
  // countries: {
  //   current: {
  //     layers: {
  //       current: {
  //         week: {
  //           current: { metadata: {}, crs: {}, features: {} },
  //         },
  //       },
  //       cyber: {
  //         week: {
  //           current: { metadata: {}, crs: {}, features: {} },
  //         },
  //         0: { metadata: {}, crs: {}, features: {} },
  //       },
  //     },
  //   },
  //   NL: {
  //     layers: {
  //       cyber: {
  //         week: {
  //           0: { metadata: {}, crs: {}, features: {} },
  //         },
  //       },
  //     },
  //   },
  // },
  // flat: [],
  loading: 0,
  // one or more API calls failed to load (fetch error, JSON error, etc)
  loadFailure: false,
});

// getters are like the computed properties of stores
const getters = {
  // all visible countries and layers
  countries_and_layers: function (state, getters, rootState) {
    return {
      ...rootState.custom_countries_and_layers,
      ...rootState.config.country_and_layers,
    };
  },
  // returns the mapData according to the selected date
  mapData: function (state) {
    return state.mapDataPerDate['latest'][0];
  },
  // returns features over all countries and layers as an array of objects
  flat: function (state) {
    let features = [];

    const countries = state.perDate['latest']?.countries;

    for (let country in countries) {
      const layers = countries[country].layers;
      for (let layer in layers) {
        for (let feature in layers[layer].features) {
          features.push({
            country: country,
            layer: layer,
            ...layers[layer].features[feature],
          });
        }
      }
    }

    return features;
  },
};

function fetchMapData(country, layer, date, daysBack, commit){
  // amount of days we look into the past if we don't have data for today
  const fallbackDays = 1;

  http.get(`/data/map/${country}/${layer}/${date}/`, {responseType: 'text'}).then((data) => {
        // this is ugly, but updating the object with ... does not do a deep merge
        commit("addMapData", { mapData: data.data, country, layer, date: 'latest' });
        commit("doneLoading");
      }).catch((error) => {
        if (error.response.status == 404 && daysBack <= fallbackDays){
          console.log(`warning: data for ${date} not available yet, getting data from day before`);
          let dayBefore = new Date()
          dayBefore.setDate(dayBefore.getDate() - daysBack)
          fetchMapData(country, layer, dayBefore.toISOString().slice(0, 10), daysBack + 1, commit);
        } else {
          console.log(`warning: failed to fetch mapdata: ${error}`);
          commit("loadFailure");
          commit("doneLoading");
        }
      });
}

const actions = {
  // load all map data for all selected countries/layers
  loadAllMapData: function ({ dispatch, getters }) {
    for (let country in getters.countries_and_layers) {
      for (let layer in getters.countries_and_layers[country].layers) {
        const layer_name = getters.countries_and_layers[country].layers[layer];
        dispatch("loadMapData", { country, layer: layer_name });
      }
    }
  },
  // load map data for a specific country/layer
  loadMapData: function ({ state, commit }, { country, layer }) {
    // skip if we already have the data
    if (state.perDate['latest']?.countries?.[country]?.layers[layer] !== undefined) {
      return;
    }
    commit("loading");

    fetchMapData(country, layer, state.currentDate, 1, commit);
  },
};

const mutations = {
  loading(state) {
    state.loading++;
  },
  doneLoading(state) {
    state.loading--;
  },
  loadFailure(state){
    state.loadFailure = true;
  },
  addMapData(state, { country, layer, mapData, date }) {
    let mapdata = state.perDate;
    if (!mapdata) {
      mapdata = {};
    }
    if (!mapdata[date]) {
      mapdata[date] = {};
    }
    if (!mapdata[date].countries) {
      mapdata[date].countries = {};
    }
    if (!mapdata[date].countries[country]) {
      mapdata[date].countries[country] = {};
    }
    if (!mapdata[date].countries[country].layers) {
      mapdata[date].countries[country].layers = {};
    }

    mapdata[date].countries[country].layers[layer] = mapData;

    state.perDate = { ...state.perDate, ...mapdata };
  },
  // setProducts (state, products) {
  //   state.all = products
  // },

  // decrementProductInventory (state, { id }) {
  //   const product = state.all.find(product => product.id === id)
  //   product.inventory--
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
