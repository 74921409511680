<template>

  <b-nav-item-dropdown :text="languagemapping[$i18n.locale]" v-if="languages.length > 1">
    <b-dropdown-item @click="selectedlocale = lang"
                     v-for="lang in languages"
                     :key="lang"
                     :value="lang">
      <span v-if="$i18n.locale === lang">&checkmark; </span>
      <small-flag :country="lang"></small-flag> {{ languagemapping[lang] }}
    </b-dropdown-item>
  </b-nav-item-dropdown>

  <!-- The old way:
  <b-select v-model="selectedlocale">
    <option
      v-for="lang in languages"
      :key="lang"
      :value="lang">
      {{ languagemapping[lang] }}
    </option>
  </b-select>-->

</template>
<script>
import SmallFlag from "@/components/SmallFlag";
export default {
  name: "SwitchLocale",
  components: {SmallFlag},
  data() {
    return {
      languages: [],
      // cant use i18n here, because then switching doesn't work anymore. And because it's always in the native language
      // translations always are the same.
      languagemapping: {
        "en": "English",
        "nl": "Nederlands",
        "fr": "Français",
        "de": "Deutsch",
        "es": "Español",
        "it": "Italiano",
        "pt": "Português",
        "pl": "Polski",
        "dk": "Dansk",
        "se": "Svenska",
        "no": "Norsk",
        "fi": "Suomi",
      },
      selectedlocale: 'en',
    }
  },
  mounted() {
      this.languages = this.$store.state.config.app.supported_locales;
  },
  created: function () {
    // prefer saved locale over default locale
    this.initialize_locale()
    this.selectedlocale = this.$store.state.locale ? this.$store.state.locale : this.getBrowserLocales({languageCodeOnly: true})[0];
  },
  methods: {
    initialize_locale() {
      if (!this.languages.includes(this.$i18n.locale))
        this.$i18n.locale = 'en'
    },
    // https://phrase.com/blog/posts/detecting-a-users-locale/
    getBrowserLocales(options = {}) {
      const defaultOptions = {
        languageCodeOnly: false,
      };
      const opt = {
        ...defaultOptions,
        ...options,
      };
      const browserLocales =
        navigator.languages === undefined ? [navigator.language] : navigator.languages;
      if (!browserLocales) {
        return undefined;
      }
      return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
        return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
      });
    }
  },
  watch: {
    selectedlocale: function (locale) {
      this.$i18n.locale = locale;
      this.$store.commit('change', {locale: locale});
    },
    // probably not needed
    // "$store.state.config.app": {
    //   deep: true,
    //   handler: function (newVal) {
    //     this.languages = newVal;
    //     this.$forceUpdate();
    //   }
    // }
  }
}
</script>
