import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from 'vuex-persistedstate';
import MapData from "./modules/MapData";

Vue.use(Vuex);

// cache for usage in color scheme, retrieving css variable name values
// hope this goes well all the time, and this has no timing issues
const computed_style = getComputedStyle(document.body);

const store = new Vuex.Store({
  modules: {
    MapData,
  },
  state: {
    // login states
    user: {
      is_authenticated: false,
      is_superuser: false,
      is_adminr: false,
    },

    // support historic metrics using the time machine feature.
    // The time machine attempts to get data from a specific date (if there is any)
    time_machine_date: null,

    // map filtering allows to view one specific issue over all maps.
    map_filter: "",

    // report filteron on url when clicking on an url:
    clicked_url: "",

    reported_organization: {
      id: null,
      name: null,
    },

    report: {
      show_high_in_report: true,
      show_low_in_report: true,
      show_medium_in_report: true,
      show_good_in_report: true,
      issue_filter: [],
    },

    // the range of statistics on several pages:
    statistics_amount_of_days: 92,


    // admin editing, moving points
    selected_point: null,

    // products information provided by chatgpt, what could possibly go wrong :)
    technologies: {},
    retrieved_technologies: false,

    // background information about cookies
    cookie_indicators: {},
    retrieved_cookie_indicators: false,

    // allows location sharing, which should not be asked every time the app starts due it being naggy
    // Javascript GeoLocationObject
    // current_geolocation.coords.latitude
    // current_geolocation.coords.longitude
    current_geolocation: undefined,

    // list of object containing the layer name which points to a number of organizations that are nearby.
    // this is created by the individual maps when location sharing is enabled. If location sharing is disabled
    // this list will be empty of course and you should ask for location sharing.
    // eg:
    nearby_organizations: {},

    // These are layers added via the state of the app and will be added on load of the site. This helps with
    // reloading reports when an organization went trough the effort of adding this layer. It will create some
    // issues where other people have NOT yet added this to their custom layers and thus will not be able to open
    // the report and get a not found page. This is warned about on the state page.
    // contains objects with {'country': 'NL', 'layer': 'layer_name'}
    custom_countries_and_layers: [],

    // list of objects that contain the organization_id, country, layer and name of the organization so a direct link
    // can be reconstructed to this report across layers. Also contains date for when this was watched.
    // The last ones are the most recent items.
    // Contains: country, layer, organization_id, date, organization_name
    recently_watched_reports: [],

    // application configuration
    config: {
      show: {
        intro: false,
        charts: false,
        comply_or_explain: false,
        datasets: false,
        announcement: false,
        statistics: false,
        numbers: false,
        improvements: false,
        graphs: false,
        changes: false,
        ticker: false,
        services: false,
        services_table: false,
        plus_info: false,
        report_numbers: false,
        report_charts: false,
        report_charts_network: false,
        report_risk_summary: false,
        report_content: false,
        report_website_gallery: false,
        report_server_locations: false,
        disclaimer: false,
        authenticated: false,
        incorrect_finding: false,
        send_in_new_domains: false,
        multi_map_summary_table: false,
        time_machine: false,
        login_plaza: false,
        cookie_plaza: false,
        historic_comparison_reports: false,
        metric_progress: false,
        geolocation_results: true,
        report_expert_view_certificates: true,
        report_expert_view_cookies: false,
        snow: false,
        thank_you: false,
        send_to_supplier: false,
        leaderboard: false,
        socials: {
          report: false,
          map: false,
          multi_map: false,
          charts: false,
          statistics: false,
        },
        issues: {
          ftp: true,
          plain_http: true,
          dnssec: true,
          http_security_header_strict_transport_security: true,
          http_security_header_x_content_type_options: true,
          http_security_header_x_frame_options: true,
          http_security_header_x_xss_protection: true,
          tls_qualys_certificate_trusted: true,
          tls_qualys_encryption_quality: true,
          internet_nl_mail_starttls_tls_available: true,
          internet_nl_mail_auth_spf_exist: true,
          internet_nl_mail_auth_dkim_exist: true,
          internet_nl_mail_auth_dmarc_exist: true,
          show_internet_nl_v6_web_ipv6: false,
          show_internet_nl_v6_web_ipv6_ready: false,
          show_internet_nl_v6_web_ipv6_proof: false,
          show_internet_nl_v6_web_ipv6_incompatible: false,
          show_internet_nl_v6_mail_ipv6: false,
          show_internet_nl_v6_mail_ipv6_ready: false,
          show_internet_nl_v6_mail_ipv6_proof: false,
          show_internet_nl_v6_mail_ipv6_incompatible: false,
          internet_nl_mail_rpki_exists: false,
          internet_nl_web_rpki_exists: false,
          ports: false,
          internet_nl_wsm_web_appsecpriv_securitytxt: false,
          bannergrab: false,
        }
      },
      public_submissions: {
        'enabled': false,
      },
      leaderboard: {
        show: false,
        default_order: ["cyber", "government", "healthcare"],
      },
      announcement: "",
      disclaimer: "",
      responsible_organization: {
        name: "",
        promo_text: "",
        website: "",
        mail: "",
        twitter: "",
        facebook: "",
        linkedin: "",
        whatsapp: "",
        phone: ""
      },
      project: {
        name: "",
        tagline: "",
        country: "NL",
        mail: "",
        issue_mail: "",
        twitter: "",
        facebook: ""
      },
      // will be filled by backend, some placeholders here are added because of testcases
      translation: {
        "nl": {
          "info": {
            "info_title": "info_title",
            "info_heading": "info_heading",
            "info_title_1": "info_title_1",
            "info_content_1": "info_content_1",
            "info_title_2": "info_title_2",
            "info_content_2": "info_content_2",
            "info_title_3": "info_title_3",
            "info_content_3": "info_content_3",
            "info_title_4": "info_title_4",
            "info_content_4": "info_content_4",
            "info_content_5": "info_content_5"
          },
          "intro": {
            "intro_title": "intro_title",
            "intro_leader": "intro_leader"
          },
          "search": {
            "site_wide_search_title": "site_wide_search_title"
          },
          "footer": {
            "footer_content": "footer_content"
          },
          "finding": {
            "finding_mail_thank_you_subject": "finding_mail_thank_you_subject",
            "finding_mail_thank_you_body": "finding_mail_thank_you_body",
            "finding_mail_will_fix_subject": "finding_mail_will_fix_subject",
            "finding_mail_will_fix_body": "finding_mail_will_fix_body",
            "finding_mail_send_to_supplier_subject": "finding_mail_send_to_supplier_subject",
            "finding_mail_send_to_supplier_body": "finding_mail_send_to_supplier_body",
            "finding_mail_comply_or_explain_subject": "finding_mail_comply_or_explain_subject",
            "finding_mail_comply_or_explain_body": "finding_mail_comply_or_explain_body",
            "finding_mail_incorrect_subject": "finding_mail_incorrect_subject",
            "finding_mail_incorrect_body": "finding_mail_incorrect_body"
          },
          "organization": {
            "organization_mail_new_domains_subject": "organization_mail_new_domains_subject",
            "organization_mail_new_domain_body": "organization_mail_new_domain_body"
          },
          "layer": {
            "layer_municipality": "layer_municipality"
          }
        },
        "en": {
          "info": {
            "info_title": "info_title",
            "info_heading": "info_heading",
            "info_title_1": "info_title_1",
            "info_content_1": "info_content_1",
            "info_title_2": "info_title_2",
            "info_content_2": "info_content_2",
            "info_title_3": "info_title_3",
            "info_content_3": "info_content_3",
            "info_title_4": "info_title_4",
            "info_content_4": "info_content_4",
            "info_content_5": "info_content_5"
          },
          "intro": {
            "intro_title": "intro_title",
            "intro_leader": "intro_leader"
          },
          "search": {
            "site_wide_search_title": "site_wide_search_title"
          },
          "footer": {
            "footer_content": "footer_content"
          },
          "finding": {
            "finding_mail_thank_you_subject": "finding_mail_thank_you_subject",
            "finding_mail_thank_you_body": "finding_mail_thank_you_body",
            "finding_mail_will_fix_subject": "finding_mail_will_fix_subject",
            "finding_mail_will_fix_body": "finding_mail_will_fix_body",
            "finding_mail_send_to_supplier_subject": "finding_mail_send_to_supplier_subject",
            "finding_mail_send_to_supplier_body": "finding_mail_send_to_supplier_body",
            "finding_mail_comply_or_explain_subject": "finding_mail_comply_or_explain_subject",
            "finding_mail_comply_or_explain_body": "finding_mail_comply_or_explain_body",
            "finding_mail_incorrect_subject": "finding_mail_incorrect_subject",
            "finding_mail_incorrect_body": "finding_mail_incorrect_body"
          },
          "organization": {
            "organization_mail_new_domains_subject": "organization_mail_new_domains_subject",
            "organization_mail_new_domain_body": "organization_mail_new_domain_body"
          },
          "layer": {
            "layer_municipality": "layer_municipality"
          }
        },
      },
      responsible_disclosure_page: {
        show: false,
        email_address: "",
        pgp_key: "",
        salsa_key: "",
      },
      comply_or_explain: {email_address: ""},
      send_in_new_domains: {email_address: ""},
      incorrect_finding: {email_address: ""},
      debug: false,
      admin: false,
      country_and_layers: {
        NL: {
          code: "NL",
          name: "Netherlands",
          flag: "/static/flags/nl.gif",
          layers: ["municipality"]
        }
      },
      "app": {
        supported_locales: ['en', 'nl'],
        menu: "single",
        custom_css: "",
        logo_image: "",
        favicon: "websecmap",
        send_to_supplier_bcc_address: "",
        "regions": {
          "mail": {
            "countries": {
              "ok": ["AW", "BQ", "CW", "SX"],
              "low": [],
              "medium": [],
              "high": []
            },
            "continents": {
              "ok": ["EU"],
              "low": [],
              "medium": [],
              "high": []
            },
          },
          "server": {
            "countries": {
              "ok": ["AW", "BQ", "CW", "SX"],
              "low": [],
              "medium": [],
              "high": []
            },
            "continents": {
              "ok": ["EU"],
              "low": [],
              "medium": [],
              "high": []
            },
          },
          "content": {
            "countries": {
              "ok": ["AW", "BQ", "CW", "SX"],
              "low": [],
              "medium": [],
              "high": []
            },
            "continents": {
              "ok": ["EU"],
              "low": [],
              "medium": [],
              "high": []
            },
          }
        },
        thank_you_email_address: "",
        relevant_for_ui: {
          high: true,
          medium: true,
          low: true,
          good: true
        },
        landing_pages: {}
      },
      google_maps_api_key: "",
      grading_policy: "",
    },

    color_scheme: {
      high_background: computed_style.getPropertyValue('--high-map') || '',
      high_border: computed_style.getPropertyValue('--high-dark'),
      medium_background: computed_style.getPropertyValue('--medium-map'),
      medium_border: computed_style.getPropertyValue('--medium-dark'),
      low_background: computed_style.getPropertyValue('--low-map'),
      low_border: computed_style.getPropertyValue('--low-dark'),
      good_background: computed_style.getPropertyValue('--good-map'),
      good_border: computed_style.getPropertyValue('--good-dark'),
      // some use "ok" instead of good...
      ok_background: computed_style.getPropertyValue('--good-map'),
      ok_border: computed_style.getPropertyValue('--good-dark'),
      addresses_background: computed_style.getPropertyValue('--addresses-back'),
      addresses_border: computed_style.getPropertyValue('--addresses-border'),
      services_background: computed_style.getPropertyValue('--services-back'),
      services_border: computed_style.getPropertyValue('--services-border'),
    },

    // loaded at boot
    issues: {}
  },

  mutations: {
    change(state, payload) {
      state = Object.assign(state, payload)
    },
    set_user(state, value) {
      state.user = value;
    },

    set_selected_point(state, value) {
      state.selected_point = value;
    },

  },

  plugins: [
    createPersistedState({
      // TODO: place these under 1 key, eg: store.persist.recently_watch_reports
      paths: [
        "recently_watched_reports",
        "user",
        "report",
        "custom_countries_and_layers",
        "locale",
        "country",
        "layer",
        "scan_types",
        "debug",
        "admin"
      ],
    }),
  ],
});


export default store;
