<template>
    <b-container class="p-3 mb-3 mt-2 rounded bg-white" fluid="xl">
        <b-row>
            <b-col>
                <slot>
                    &nbsp;
                </slot>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {}
</script>
