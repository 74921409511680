<template>
  <span :class="`fi fi-${country.replace('en', 'gb')}`"/>
</template>

<script>
export default {
  name: "SmallFlag",
  props: {
    country: {type: String, required: false, default: "en"}
  }
}
</script>

<style scoped>

</style>
