<template>
  <div v-if="loading" class="p-2 fullwidth">
    <div style="font-size: 2em; text-align: center;">
      <b-spinner variant="success" type="grow" label="Spinning" style="width: 2rem; height: 2rem;"></b-spinner>
      {{ $t("app.core.loading") }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
